<template>
  <router-view></router-view>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch('captures/item', {
      id: this.$route.params.captureId,
      comparing: true
    }).catch(() => {
      this.$router.push('not-found')
    });
    this.loaded = true;
  }
}
</script>